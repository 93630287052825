// Mapping between URL param value and HTML ID
const scrollToElements = {
  snapcodes: "snapcodes-section"
}

export function isCurrentView(viewPath) {
  const currentView = document.querySelectorAll(viewPath);

  return currentView && currentView.length > 0 ? true : false;
}

export function hasCurrentView(views) {
  for (let i = 0; i < views.length; i++) {
    if (isCurrentView(views[i]) === true) {
      return true;
    }
  };

  return false;
}

export function animateElement(shouldAnimate, element, shouldShow) {
  if (shouldShow) {
    shouldAnimate ? element.fadeIn(150) : element.show();
  } else {
    shouldAnimate ? element.fadeOut(150) : element.hide();
  }
}

export function scrollToElement(section) {
  const elementId = scrollToElements[section]

  if (!elementId) { return }

  headerOffset = document.getElementById("site-header").offsetHeight;
  element = document.getElementById(elementId);

  if (!element) { return }

  const paddingTop = 20;

  const y = element.getBoundingClientRect().top - (headerOffset + paddingTop);

  window.scrollTo({ top: y, behavior: 'smooth' });
}

export function isPasswordFieldInvalid(hasPrependIcon = false, wrapper) {
  const inputIndex = hasPrependIcon ? 1 : 0;

  if (
    wrapper &&
    wrapper.children &&
    wrapper.children[0] &&
    wrapper.children[0].children &&
    wrapper.children[0].children[inputIndex] &&
    wrapper.children[0].children[inputIndex].classList.contains('is-invalid')
  ) {
    wrapper.classList.add('is-invalid');
    return true;
  } else {
    return false;
  }
}

export function initializePasswordIconState(passwordInput, passwordWrapper, error = false) {
  if (passwordInput.type === 'password') {
    passwordWrapper.classList.remove('eye-open-red');
    passwordWrapper.classList.remove('eye-open');
    passwordWrapper.classList.add(error ? 'eye-open-red' : 'eye-open');
  } else {
    passwordWrapper.classList.remove('eye-closed-red');
    passwordWrapper.classList.remove('eye-closed');
    passwordWrapper.classList.add(error ? 'eye-closed-red' : 'eye-closed');
  }
}

export function togglePassword(passwordInput, passwordWrapper, error = false) {
  passwordWrapper.getElementsByClassName("input-group-append")[0].addEventListener("click", (event) => {
    if (passwordInput.type === 'password') {
      passwordInput.type = 'text';
      passwordWrapper.classList.remove('eye-open-red');
      passwordWrapper.classList.remove('eye-open');
      passwordWrapper.classList.add(error ? 'eye-closed-red' : 'eye-closed');
    } else {
      passwordInput.type = 'password';
      passwordWrapper.classList.remove('eye-closed-red');
      passwordWrapper.classList.remove('eye-closed');
      passwordWrapper.classList.add(error ? 'eye-open-red' : 'eye-open');
    }
  });
}

export function strip(value) {
  return value.replace(/\s+/g, ''); // Removes all whitespaces
}

export function updateCheckboxState(checkbox, state) {
  checkbox.checked = state;
}

export function hideAlertEventListener(targetedClass, id) {
  $(`${targetedClass}`).on("click", function (e) {
    if (!$(`#${id}`)) { return; }

    $(`#${id}`).hide();
  });
}

export function initializeClipboard(shouldTrigger) {
  if (!shouldTrigger) { return; }

  // ClipboardJS
  new Clipboard(".clipboard-btn");

  $(".clipboard-btn").tooltip({
    trigger: "click",
    placement: "bottom",
  });

  $(".toast-clipboard").on("click", showToast);
}

export function showToast() {
  if (!document.contains($("#link-toast-id").get(0))) {
    $("#container-id").append(
      "<div class='toast-alert alert' data-static='static' id='link-toast-id'>Payment Link copied to clipboard</div>"
    );

    setTimeout(() => {
      $("#link-toast-id").remove();
    }, 3000);
  }
}

export function toggleDisplay(container) {
  if (!container) { return; }

  container.classList.toggle('animate');
}

export function show(container) {
  if (!container) { return; }
  container.classList.remove('tw-hidden');
  container.classList.add('tw-flex');
}


export function replaceUrlParam(isValidUrl, url, paramName, paramValue) {
  const baseUrl = 'https://base';
  // Add a base url to the current url if its not a valid url to construct
  let newUrl = isValidUrl ? new URL(url) : new URL(url, baseUrl);
  // Set the query param value
  newUrl.searchParams.set(paramName, paramValue);

  // Return newUrl with updated query param
  return isValidUrl ? newUrl.toString() : newUrl.toString().slice(baseUrl.length);
}

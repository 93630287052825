import { isCurrentView, hideAlertEventListener } from '../../../shared/utils/view-util';

$(() => {
  if (!isCurrentView(".sbsa-portal.setup")) { return; }

  $(".completed").on("click", (e) => {
    e.stopImmediatePropagation();
    e.preventDefault();
  });

  hideAlertEventListener(".close-warning", "feedback-alert");
});

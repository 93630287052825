import { isCurrentView } from '../../../shared/utils/view-util';
import { updateInputLabelDisplay } from '../../../shared/utils/input-utils';
import { applyFoundSubEntitiesToSelect } from '../../../shared/utils/business-type-select-utils';
import {
  changeSelectReadOnlyState,
  appendToSelect,
  emptySelect
} from '../../../shared/utils/select-utils';
import { getEntityTypesAndOptions } from '../../../shared/internal-pos-api/merchant-api';

$(() => {
  if (!isCurrentView(".sbsa-signup.business_type")) { return; }

  const form = "business_type_form";
  const typeSelect = $(`#${form}_type_of_merchant`);
  const subTypeSelect = $(`#${form}_sub_type_of_merchant`);
  const persistedMerchantValue = $(`#${form}_persisted_type_of_merchant`).val();
  const persistedSubTypeValue = $(
    `#${form}_persisted_sub_type_of_merchant`
  ).val();

  updateInputLabelDisplay(
    `label[for='${form}_merchant_name']`,
    `#${form}_merchant_name`,
    `Business name`
  );

  if (
    (!persistedMerchantValue || persistedMerchantValue === "deprecated") &&
    (!$(typeSelect).val() || $(typeSelect).val() === "deprecated")
  ) {
    changeSelectReadOnlyState(subTypeSelect, true);
    $(typeSelect).removeClass("is-valid");
    $(subTypeSelect).removeClass("is-valid");
  }

  const entities = getEntityTypesAndOptions().then((response) => {
    response.forEach((entity) =>
      appendToSelect(typeSelect, entity.id, entity.name)
    );

    if (response.find((entity) => entity.id === persistedMerchantValue)) {
      $(typeSelect).val(persistedMerchantValue).trigger("change", true);
    }

    return response;
  });

  $(typeSelect).on('change', (event, isOnLoad) => {
    entities.then((entities) => {
      const foundEntity = entities.find(
        (entity) => entity.id === $(`#${form}_type_of_merchant :selected`).val()
      );

      foundEntity
        ? changeSelectReadOnlyState(subTypeSelect, false)
        : changeSelectReadOnlyState(subTypeSelect, true);

      foundEntity && foundEntity.sub_types
        ? applyFoundSubEntitiesToSelect(
          isOnLoad,
          persistedSubTypeValue,
          foundEntity.sub_types,
          subTypeSelect
        )
        : emptySelect(subTypeSelect);
    });
  });

  // All parent href's
  const hrefElements = [
    "#collapse-1",
    "#collapse-2",
    "#collapse-3",
    "#collapse-4",
  ];

  $("a").on("click", function (event) {
    toggleChevron(hrefElements, event);
  });
});

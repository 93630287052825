export function updateInputLabelDisplay(label, input, textMapping, required = true) {
  if (!label || !input || !textMapping) { return };

  $(label).text(textMapping);
  $(input).attr('placeholder', textMapping);

  if (required) {
    const abbr = '<abbr title="required"> *</abbr>'
    $(label).append(abbr);
  }
}

import {
  populateChildSelect,
  appendToSelect,
  emptySelect,
  changeSelectReadOnlyState
} from './select-utils'

import { getIndustries, getActivities } from '../internal-pos-api/merchant-api'

export function applyFoundSubEntitiesToSelect(isPersisted, persistedValue, entities, select) {
  // Clear all the options of the sub type of merchant dropdown
  emptySelect(select);

  // Append all the new options to the sub type of merchant dropdown
  entities.forEach(entity => appendToSelect(select, entity.id, entity.name));

  populateChildSelect(entities, select);

  if (isPersisted) {
    $(select).val(persistedValue).trigger('change');
  }
}

export function isPersistedOptionFound(response, persistedValue, select, callback) {
  changeSelectReadOnlyState(select, false);

  // Early return if there is no persisted value
  if (!persistedValue) { return true; }

  const isPersistedOptionFound = response && response.find((option) => option.id === persistedValue);

  if (isPersistedOptionFound) {
    return true;
  } else {
    $(select).val(null).trigger('change', true);
    callback && callback.reject();

    return false;
  }
}

export async function setIndustriesSelect(isPersisted, persistedValue, sectionCallback, sectionSelect) {
  getIndustries().then((response) => {
    emptySelect(sectionSelect);

    // Early return if the response has an error and prevent the following API's from firing
    if (response && response.status === "error") {
      sectionCallback && sectionCallback.reject();
      return;
    }

    response && response.forEach(industry => appendToSelect(sectionSelect, industry.id, industry.name));

    /*
        Return's the function if the persisted value is not found or is incorrect and prevents following callbacks from firing.
        This also keeps the disabled state on the next dropdown's to prevent users from clicking on empty dropdown's.
    */
    if (!isPersistedOptionFound(response, persistedValue, sectionSelect, sectionCallback)) {
      return;
    };

    if (isPersisted) {
      $(sectionSelect).val(persistedValue).trigger('change', true);
      sectionCallback.resolve();
    } else {
      populateChildSelect(response, sectionSelect);
    }
  });
}

export async function setActivitiesSelect(isPersisted, persistedValue, selectedSection, industrySelect) {
  getActivities(selectedSection).then((response) => {
    emptySelect(industrySelect);

    // Early return if the response has an error
    if (response && response.status === "error") {
      return;
    }

    response && response.forEach(activity => appendToSelect(industrySelect, activity.id, activity.name));

    /*
        Return's the function if the persisted value is not found or is incorrect.
    */
    if (!isPersistedOptionFound(response, persistedValue, industrySelect, null)) {
      return;
    };

    if (isPersisted) {
      $(industrySelect).val(persistedValue).trigger('change', true);

      // Disable select if there is only one option
      if (response && response.length === 1) {
        changeSelectReadOnlyState(industrySelect, true);
      }
    } else {
      populateChildSelect(response, industrySelect);
    }
  });
}

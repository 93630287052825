import { isCurrentView } from '../../../shared/utils/view-util';
import { initializeBusinessTypesAndChangeDetection } from '../../../shared/utils/business-overview-util';

$(() => {
  if (!isCurrentView('.sbsa-signup.business_activity')) { return; }

  const form = 'business_activity_form';
  const businessSectionSelect = $(`#${form}_merchant_industry_section`);
  const businessIndustrySelect = $(`#${form}_merchant_industry`);
  const persistedSectionValue = $(`#${form}_persisted_merchant_industry_section`).val();
  const persistedIndustryValue = $(`#${form}_persisted_merchant_industry`).val();
  const sectionCallback = $.Deferred();

  /*
      Fires all the logic related to the business

      Following attributes:
      type_of_merchant, sub_type_of_merchant, merchant_industry_section,
      merchant_industry
  */
  initializeBusinessTypesAndChangeDetection(
    form,
    businessSectionSelect,
    businessIndustrySelect,
    persistedSectionValue,
    persistedIndustryValue,
    sectionCallback,
  );
});

export function applyStepperClass(min, max, orderQuantity, removeElement, addElement) {
  if (orderQuantity && removeElement && addElement) {
    orderQuantity.value == min ? removeElement.classList.add('disabled') : removeElement.classList.remove('disabled');
    orderQuantity.value == max ? addElement.classList.add('disabled') : addElement.classList.remove('disabled');
  }
}

export function stepperIncrementListener(element, orderQuantity, min, max, removeElement, addElement) {
  if (element) {
    element.addEventListener('click', function () {
      if (orderQuantity.value < max) {
        orderQuantity.value = parseInt(orderQuantity.value) + 1;
      }

      applyStepperClass(min, max, orderQuantity, removeElement, addElement);
    });
  }
}

export function stepperDecrementListener(element, orderQuantity, min, max, removeElement, addElement) {
  if (element) {
    element.addEventListener('click', function () {
      if (orderQuantity.value > min) {
        orderQuantity.value = parseInt(orderQuantity.value) - 1;
      }

      applyStepperClass(min, max, orderQuantity, removeElement, addElement);
    });
  }
}

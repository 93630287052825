import {
  isCurrentView,
  isPasswordFieldInvalid,
  initializePasswordIconState,
  togglePassword
} from '../../../shared/utils/view-util';

$(() => {
  if (!isCurrentView('.sbsa-portal.personal_settings.personal_information')) { return; }

  $("#personal_information_form_contact_number").on('input', (e) => {
    // Remove white spaces as the user types or pastes
    if (e && e.target && e.target.value) {
      e.target.value = e.target.value.replace(/\s+/g, '');
    }
  });
});

$(() => {
  if (!isCurrentView('.sbsa-portal.personal_settings.update_password, .sbsa-portal.personal_settings.change_password')) { return; }

  // Current password
  const userCurrentPassword = document.getElementById('user_current_password');
  const currentPasswordWrapper = document.getElementById("current_password_wrapper");

  // New password
  const userPassword = document.getElementById('user_password');
  const userPasswordWrapper = document.getElementById("password_wrapper");

  // Confirm new password
  const userPasswordConfirmation = document.getElementById('user_password_confirmation');
  const passwordConfirmationWrapper = document.getElementById("password_confirmation_wrapper");

  const isCurrentPasswordInvalid = isPasswordFieldInvalid(true, currentPasswordWrapper);
  const isUserPasswordInvalid = isPasswordFieldInvalid(true, userPasswordWrapper);
  const isConfirmationPasswordInvalid = isPasswordFieldInvalid(true, passwordConfirmationWrapper);

  // Initial state
  initializePasswordIconState(userCurrentPassword, currentPasswordWrapper, isCurrentPasswordInvalid);
  initializePasswordIconState(userPassword, userPasswordWrapper, isUserPasswordInvalid);
  initializePasswordIconState(userPasswordConfirmation, passwordConfirmationWrapper, isConfirmationPasswordInvalid);

  // Add event listeners
  togglePassword(userCurrentPassword, currentPasswordWrapper, isCurrentPasswordInvalid);
  togglePassword(userPassword, userPasswordWrapper, isUserPasswordInvalid);
  togglePassword(userPasswordConfirmation, passwordConfirmationWrapper, isConfirmationPasswordInvalid);
});

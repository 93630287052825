import { toggleSelectPlaceholderState } from './select-utils'

export function initAutocomplete(address1Field, address2Field, postalField, suburbField, cityField, provinceField) {
  if (!address1Field || !address2Field || !postalField || !suburbField || !cityField || !provinceField) return;

  let autocomplete = new google.maps.places.Autocomplete(address1Field, {
    componentRestrictions: { country: ["za"] },
    fields: ["address_components", "geometry"],
    types: ["address"],
  });

  if (address1Field) {
    address1Field.focus();
  }

  autocomplete.addListener("place_changed", () => fillInAddress(autocomplete, address1Field, address2Field, postalField, suburbField, cityField, provinceField));
}

// Related parties does not have province on the model so autofill the rest
export function initRelatedPartyAutocomplete(address1Field, address2Field, postalField, suburbField, cityField) {
  if (!address1Field || !address2Field || !postalField || !suburbField || !cityField) return;

  let autocomplete = new google.maps.places.Autocomplete(address1Field, {
    componentRestrictions: { country: ["za"] },
    fields: ["address_components", "geometry"],
    types: ["address"],
  });

  if (address1Field) {
    address1Field.focus();
  }

  autocomplete.addListener("place_changed", () => fillInAddress(autocomplete, address1Field, address2Field, postalField, suburbField, cityField, null));
}

function generateGoogleAnalyticsId() {

  let pageTitle = "";

  if (document && document.title) {
    pageTitle = document.title.replace("SnapScan Merchant Portal", "");
  }

  if (pageTitle === "") {
    pageTitle = "Home"
  };

  return `PAC - ${pageTitle}`;
}

function fillInAddress(autocomplete, address1Field, address2Field, postalField, suburbField, cityField, provinceField) {
  // Creates an event for Google Tag Manager to track Places autofill clicks
  window.dataLayer.push({
    event: "pacClick",
    ga_identifier: generateGoogleAnalyticsId()
  });

  const place = autocomplete.getPlace();
  let address1 = "";
  let postcode = "";

  for (const component of place.address_components) {

    const componentType = component && component.types[0]

    switch (componentType) {

      case "street_number": {
        address1 = `${component.long_name} ${address1}`;
        break;
      }

      case "route": {
        address1 += component.long_name;
        break;
      }

      case "sublocality_level_1": {
        suburbField.value = component.long_name;
        break;
      }

      // Suburb fallback. Note that Places in some instances doesn't have either sublocality_level_1 or 2. See: 8 5th Avenue, Edenvale
      case "sublocality_level_2": {
        suburbField.value = component.long_name;
        break;
      }

      case "postal_code": {
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      case "postal_code_suffix": {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }

      case "locality": {
        cityField.value = component.long_name;
        break;
      }

      case "administrative_area_level_1": {
        if (provinceField) {
          provinceField.value = component.short_name
          toggleSelectPlaceholderState();
        }
        break;
      }

    }

  }

  address1Field.value = address1;
  postalField.value = postcode;
  address2Field.focus();
}

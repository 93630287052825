import { isCurrentView } from '../../../shared/utils/view-util';
import { getEntityTypesAndOptions } from '../../../shared/internal-pos-api/merchant-api';
import { updateInputLabelDisplay } from '../../../shared/utils/input-utils';
import { emptySelect, appendToSelect, populateChildSelect, changeSelectReadOnlyState } from '../../../shared/utils/select-utils';
import { initializeBusinessTypesAndChangeDetection } from '../../../shared/utils/business-overview-util';

function businessDetailsToggleInputDisplay(options, form) {
  if (!form || !options) { return; }

  const entityTypes = document.getElementById('data') && JSON.parse(document.getElementById('data').getAttribute("data-mapping"));
  const npoOrganization = document.getElementById(`new_${form}`) && document.getElementById(`new_${form}`).getAttribute('data-npo-organization');

  const businessType = $(`#${form}_sub_type_of_merchant :selected`).val();
  const foundEntityTypeMappings = (
    entityTypes[businessType]
      ? entityTypes[businessType]
      // Default when none is found
      : [{ business_name_text: "Business trading name", registered_name_text: "Business registered name", registration_number_text: "Business registration number" }]
  );
  const registeredNameContainer = $('#registered_name_container');
  const registeredNumberContainer = $('#registered_number_container');
  const registrationNumberLabel = $('#registration_number_label');

  businessType == npoOrganization ? registrationNumberLabel.hide() : registrationNumberLabel.show(); // Hide description under the input

  // Set default merchant_name label/placeholder
  updateInputLabelDisplay(
    `label[for='${form}_merchant_name']`,
    `#${form}_merchant_name`,
    `${foundEntityTypeMappings[0].business_name_text}`
  );

  // Show trading name section
  if (options && options.includes('registered_business_name')) {
    updateInputLabelDisplay(
      `label[for='${form}_merchant_registered_name']`,
      `#${form}_merchant_registered_name`,
      `${foundEntityTypeMappings[0].registered_name_text}`
    );

    registeredNameContainer.show();
  } else {
    registeredNameContainer.hide();
  }

  // Show registration number section
  if (options && options.includes('registration_number')) {
    updateInputLabelDisplay(
      `label[for='${form}_merchant_registration_number']`,
      `#${form}_merchant_registration_number`,
      `${foundEntityTypeMappings[0].registration_number_text}`
    );

    registeredNumberContainer.show();
  } else {
    registeredNumberContainer.hide();
  }
}

function applyFoundSubTypeToSelect(form, isPersisted, persistedValue, entities, select) {
  // Clear all the options of the sub type of merchant dropdown
  emptySelect(select);

  // Append all the new options to the sub type of merchant dropdown
  entities.forEach(entity => appendToSelect(select, entity.id, entity.name));

  populateChildSelect(entities, select);

  if (isPersisted) {
    $(select).val(persistedValue).trigger('change', true);
  }

  // Retrieve options from sub_type
  const foundEntity = entities.find(entity => entity.id === $(`#${form}_sub_type_of_merchant :selected`).val());
  const options = foundEntity && foundEntity.options ? foundEntity.options : null;

  // Toggle the display of inputs and the label text
  businessDetailsToggleInputDisplay(options, form);
}

function initializeCheckBoxLogic(form) {
  const checkBox = `#${form}_registered_name_required`;
  const registeredNameBlock = $(`.${form}_merchant_registered_name`);
  const registeredNameInputId = $(`#${form}_merchant_registered_name`);

  $(`${checkBox}_true`).is(':checked') ? registeredNameBlock.show() : registeredNameBlock.hide();

  $(`${checkBox}_true`).on('input', function (event) {
    registeredNameInputId.val('')
    registeredNameBlock.show();
  });

  $(`${checkBox}_false`).on('input', function (event) {
    registeredNameInputId.val('')
    registeredNameBlock.hide();
  });
}

$(() => {
  if (!isCurrentView('.sbsa-portal.business_overview')) { return; }

  const form = 'business_details_overview_form';

  /*
      Business Types section
  */

  const typeSelect = $(`#${form}_type_of_merchant`);
  const subTypeSelect = document.getElementById(`${form}_sub_type_of_merchant`);
  const persistedMerchantValue = $(`#${form}_persisted_type_of_merchant`).val();
  const persistedSubTypeValue = $(`#${form}_persisted_sub_type_of_merchant`).val();

  if ((!persistedMerchantValue || persistedMerchantValue === "deprecated") && (!$(typeSelect).val() || $(typeSelect).val() === "deprecated")) {
    changeSelectReadOnlyState(subTypeSelect, true);
    $(typeSelect).removeClass("is-valid");
    $(subTypeSelect).removeClass("is-valid");
  }

  const entities = getEntityTypesAndOptions().then((response) => {
    response.forEach(entity => appendToSelect(typeSelect, entity.id, entity.name));

    if (response.find(entity => entity.id === persistedMerchantValue)) {
      $(typeSelect).val(persistedMerchantValue).trigger('change', true);
    }

    return response;
  });


  $(typeSelect).on('change', (event, isOnLoad) => {
    entities.then((entities) => {
      const foundEntity = entities.find(entity => entity.id === $(`#${form}_type_of_merchant :selected`).val());

      foundEntity
        ? changeSelectReadOnlyState(subTypeSelect, false)
        : changeSelectReadOnlyState(subTypeSelect, true);

      foundEntity && foundEntity.sub_types
        ? applyFoundSubTypeToSelect(form, isOnLoad, persistedSubTypeValue, foundEntity.sub_types, subTypeSelect)
        : emptySelect(subTypeSelect);

      // Hide both registration number and trading name when sub_type is not selected
      const sub_type_value = $(`#${form}_sub_type_of_merchant :selected`).val();
      if (sub_type_value === '' || sub_type_value === null || sub_type_value === undefined) {
        businessDetailsToggleInputDisplay([], form);
      }
    });
  });

  $(subTypeSelect).change(() => {
    entities.then((entities) => {
      const foundEntity = entities.find(entity => entity.id === $(`#${form}_type_of_merchant :selected`).val());

      // Retrieve options from sub_type
      const found_sub_type = foundEntity && foundEntity.sub_types.find(entity => entity.id === $(`#${form}_sub_type_of_merchant :selected`).val());
      const options = found_sub_type && found_sub_type.options ? found_sub_type.options : [];

      // Toggle the display of inputs and the label text
      businessDetailsToggleInputDisplay(options, form);
    });
  });

  // Initializes checkbox value and bind event handler
  initializeCheckBoxLogic(form);


  /*
      Business activities section
  */

  const businessSectionSelect = $(`#${form}_merchant_industry_section`);
  const businessIndustrySelect = $(`#${form}_merchant_industry`);
  const persistedSectionValue = $(`#${form}_persisted_merchant_industry_section`).val();
  const persistedIndustryValue = $(`#${form}_persisted_merchant_industry`).val();
  const sectionCallback = $.Deferred();

  /*
      Fires all the logic related to the business

      Following attributes:
      type_of_merchant, sub_type_of_merchant, merchant_industry_section,
      merchant_industry
  */

  initializeBusinessTypesAndChangeDetection(
    form,
    businessSectionSelect,
    businessIndustrySelect,
    persistedSectionValue,
    persistedIndustryValue,
    sectionCallback,
  );
});

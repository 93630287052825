// TODO: We need to re-write most of these into the JS approach.

// Disabled state does not send select option through the parameters
export function setSelectDisabledState(select, state) {
  // JS approach
  select.disabled = state;
}

// Read only allows parameters to be sent through parameters
export function changeSelectReadOnlyState(select, state) {
  $(select).attr('readonly', state);
  $(select).css('pointer-events', state === true ? 'none' : '');
}

export function emptySelect(select) {
  $(select).find('option:not(:first)').remove();
  $(select).trigger('change');
}

export function clearAndReadOnlySelect(select) {
  emptySelect(select);
  changeSelectReadOnlyState(select, true);
}

export function appendToSelect(select, value, text) {
  if (text === 'disabled_placeholder') {
    const placeholder = '--------------------------------------------------';
    $(select).append($(`<option disabled="disabled" value="${placeholder}">${placeholder}</option>`));
  } else {
    $(select).append($('<option>', { value, text }));
  }
}

export function populateChildSelect(options, select) {
  if (options && options.length === 1) {
    $(select).val(options[0].id).trigger('change');
    changeSelectReadOnlyState(select, true);
  } else {
    changeSelectReadOnlyState(select, false);
  }
}

export function toggleSelectPlaceholderState() {
  $('.input-float-label label').each(function (i, obj) {
    const inputId = $(obj).attr("for");

    if ($(`#${inputId}`).is("select")) {
      if (!(document.getElementById(inputId) && document.getElementById(inputId).value)) {
        $(obj).addClass("select-placeholder-label");
        $(`#${inputId}`).addClass("select-placeholder");
      } else {
        $(obj).removeClass("select-placeholder-label");
        $(`#${inputId}`).removeClass("select-placeholder");
      }
    }
  });
}

import { isCurrentView } from '../../../shared/utils/view-util';

function add_file(element, event) {
  const container = document.getElementById(`${element.id}_file_grid`);
  for (const child of container.children) {
    child.style.display = "none";
  }

  for (const value of event.target.files) {
    const pending = document.getElementById("bank_document_uploader_template").cloneNode(true);
    pending.removeAttribute('id');
    pending.querySelector(".bank_doc_name").innerHTML = value.name;
    container.append(pending);
  }
}

const BANK_FORM_DATA = {
  BANK_ACCOUNT_NAME: 0,
  BANK_ACCOUNT_NUMBER: 1,
  BANK_NAME: 2,
  LEAD_POS_REFERENCE: 3,
};
const SOURCE_OF_INCOME = "bank_form_merchant_source_of_income";

$(() => {
  if (!isCurrentView('.sbsa-portal.bank')) { return; }

  let formCheck = false;
  let uploadRequired = false;
  const element = document.getElementById('bank_form_data') && document.getElementById('bank_form_data').getAttribute("data-mapping");
  const formData = element && JSON.parse(element);

  if (!!formData) {
    formCheck =
      !!formData[BANK_FORM_DATA.BANK_ACCOUNT_NAME] &&
      !!formData[BANK_FORM_DATA.BANK_ACCOUNT_NUMBER] &&
      !!formData[BANK_FORM_DATA.BANK_NAME] &&
      !!formData[BANK_FORM_DATA.LEAD_POS_REFERENCE];
  }

  // formData[4] - Documents array
  const documentsExist = formData && formData[4] && formData[4].length > 0
  uploadRequired = documentsExist ? !!formData[4].find(f => (f.documents.length != 0 && f.status !== "completed")) : false; // uploaded_documents

  function documentShow() {
    $("#required-documents").show();
    $("#bank-info-submit").prop('disabled', false);
  }

  // Show documents required when upload is required or hidden input is true (hidden input is used for render to keep show state)
  if (uploadRequired || $("#bank_form_show_document_required").val() == "true") {
    documentShow();
  } else if (formCheck && documentsExist) {
    $(".inputs").on('input', function (e) {
      documentShow();
    });

    const sourceOfIncomeBusinessActivity = document.getElementById('bank_form_merchant_source_of_income_business_activities');
    const sourceOfIncomeDonations = document.getElementById('bank_form_merchant_source_of_income_donations');

    sourceOfIncomeBusinessActivity.addEventListener('change', () => {
      $("#bank-info-submit").prop('disabled', false);
    });

    sourceOfIncomeDonations.addEventListener('change', () => {
      $("#bank-info-submit").prop('disabled', false);
    });
  } else {
    $("#bank-info-submit").prop('disabled', false);
  }
});

import { setIndustriesSelect, setActivitiesSelect } from './business-type-select-utils';
import { clearAndReadOnlySelect, changeSelectReadOnlyState } from './select-utils';
/*
    This initializes and fetches all the persisted values from POS to populate the various options
    that gets returned.

    It also applies change detection on those specific selects so that new values can get fetched from pos
    when one of the first selects get changed.
*/
export function initializeBusinessTypesAndChangeDetection(
  form,
  businessSectionSelect,
  businessIndustrySelect,
  persistedSectionValue,
  persistedIndustryValue,
  sectionCallback,
) {
  // Initialize industries
  setIndustriesSelect(
    persistedSectionValue ? true : false,
    persistedSectionValue ? persistedSectionValue : null,
    sectionCallback,
    businessSectionSelect
  );

  // Sets the merchant_industry_section dropdown when value exists
  if (persistedSectionValue) {
    $.when(sectionCallback).done(() => {
      // Populate the activities dropdown options
      setActivitiesSelect(
        true,
        persistedIndustryValue,
        persistedSectionValue,
        businessIndustrySelect
      );
    });
  }

  // Clear and disable merchant_industry dropdown if no section is selected
  if ($(`#${form}_merchant_industry_section :selected`).val() === '') {
    clearAndReadOnlySelect(businessIndustrySelect);
  }

  $(businessSectionSelect).on('change', (event, isOnLoad) => {
    if (isOnLoad) { return; }

    emptySelect(businessIndustrySelect);
    // clearAndReadOnlySelect(businessIndustrySelect);

    $(`#${form}_merchant_industry_section :selected`).val() != ''
      ? changeSelectReadOnlyState(businessIndustrySelect, false)
      : changeSelectReadOnlyState(businessIndustrySelect, true);

    if ($(`#${form}_merchant_industry_section :selected`).val() != '') {
      setActivitiesSelect(
        false,
        null,
        $(`#${form}_merchant_industry_section :selected`).val(),
        businessIndustrySelect
      );
    }
  });
}

export async function baseFetch(url) {
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  });

  return await response.json();
}

/*
    Returns all legal entities and it's respective options that are required to capture
    example: ["registration_number", "registered_business_name"]
*/
export async function getEntityTypesAndOptions() {
  return await baseFetch("/internal/compliance/api/merchant/entities");
}

// Returns all the RelyComply sections from POS
export async function getIndustries() {
  const response = await baseFetch("/internal/compliance/api/merchant/industries");

  response.sort((a, b) => a.display_order - b.display_order);
  response.splice(9, 0, { name: "disabled_placeholder", id: null, display_order: null });

  return response
}

// Returns all the RelyComply class activities from POS
export async function getActivities(industry) {
  if (!industry) { return; }

  const response = await baseFetch(`/internal/compliance/api/merchant/activities/${industry}`);
  return response.sort((a, b) => a.name.localeCompare(b.name));
}
